<template>
  <v-expansion-panel active-class="secondary white--text">
    <v-expansion-panel-header class="px-2" :hide-actions="$vuetify.breakpoint.xsOnly">
      <v-col cols="6" md="1" lg="1" class="d-flex flex-row" v-if="!$vuetify.breakpoint.mobile">
        <span class="display" v-text="cuenta.campo2.trim() ? cuenta.campo2.trim() : 'S/N'" />
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" class="d-flex flex-row align-start">
          <v-icon left small>mdi-account-tie</v-icon>
        <div>
          <span class="font-weight-bold" :class="{'display': !$vuetify.breakpoint.mobile, 'subtitle-1': $vuetify.breakpoint.mobile }" v-text="cuenta.cli_des" /> 
          <span 
            class="font-weight-bold ml-1"
            v-text="'('+ cuenta.co_cli + ')'" />
        </div>
      </v-col>
      <v-col cols="6" sm="4" md="3" lg="3" class="d-flex flex-row align-start" v-if="$vuetify.breakpoint.smAndUp">
        <div>
          <span :class="{'display': !$vuetify.breakpoint.mobile, 'subtitle-1': $vuetify.breakpoint.mobile }" v-text="cuenta.ven_des" /> 
          <span 
            class="ml-1"
            v-text="'('+ cuenta.co_ven + ')'" />
        </div>
      </v-col>
      <v-col cols="6" sm="2" md="2" class="d-flex flex-row justify-center">
        <span class="font-weight-medium mr-1" :class="{'display': !$vuetify.breakpoint.mobile, 'subtitle-1': $vuetify.breakpoint.mobile }"><span class="mx-1" v-text="currency"></span>{{cuenta.saldo | FormatCurrency }}</span> 
        <!-- <span :class="{'subtitle-1': $vuetify.breakpoint.mobile }">{{cuenta.Moneda}}</span> -->
      </v-col>
      <!-- <v-col cols="1" class="d-flex flex-row justify-md-center justify-sm-end" v-if="!$vuetify.breakpoint.mobile">
        <span class="font-weight-medium mr-1" :class="{'display': !$vuetify.breakpoint.mobile, 'subtitle-1': $vuetify.breakpoint.mobile }">{{cuenta.TipoPrecio | precioVenta}}</span>
      </v-col>
      <v-col cols="6" md="2" lg="2" class="d-flex flex-row" v-if="!$vuetify.breakpoint.mobile">
        <v-icon left small color="icono">mdi-calendar-month-outline</v-icon>
        <span class="display">{{ cuenta.fecha_recibido | smartDate }}</span>
      </v-col> -->
       <v-col cols="1" class="d-flex flex-row align-start px-0" v-if="!$vuetify.breakpoint.mobile">
          <v-chip
            small
            color="red-alert"
            label
            class="font-weight-bold"
          >
            {{cuenta.dias_vencida}} días
          </v-chip>
       </v-col>
    </v-expansion-panel-header>
    <v-expansion-panel-content color="white" class="cxc-content">
      <v-row no-gutters>
        <v-col cols="12" sm="6" md="9" class="pb-0">
          <v-list>
            <v-list-item class="px-0">
              <v-list-item-content>
                <v-row no-gutters>
                  <v-col cols="9" md="6" lg="6" :class="{'mb-3': $vuetify.breakpoint.mobile, 'pr-3': true }">
                    <v-list-item-subtitle class="caption blue-grey--text text--darken-3 mb-1">Cliente</v-list-item-subtitle>
                    <v-list-item-title class="text-h5 font-weight-bold primary--text text-wrap" 
                      v-text="cuenta.cli_des"></v-list-item-title>
                  </v-col>
                  <v-col cols="9" md="6" lg="6" :class="{'mb-3': $vuetify.breakpoint.mobile, 'pr-3': true }">
                    <v-list-item-subtitle class="caption blue-grey--text text--darken-3 mb-1">Vendedor</v-list-item-subtitle>
                    <v-list-item-title class="text-h5 font-weight-bold primary--text text-wrap" 
                      v-text="cuenta.ven_des"></v-list-item-title>
                  </v-col>                 
                </v-row>
                <v-row no-gutters class="my-5">
                  <v-col cols="6" md="3" lg="3" :class="{'mb-3': $vuetify.breakpoint.mobile }">
                    <v-list-item-subtitle class="caption blue-grey--text text--darken-3 mb-1">Nro. Pedido</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold primary--text" v-text="cuenta.campo2.trim() ? cuenta.campo2.trim() : 'S/N'"></v-list-item-title>
                  </v-col>
                  <v-col cols="6" md="3" lg="3" :class="{'mb-3': $vuetify.breakpoint.mobile }">
                    <v-list-item-subtitle class="caption blue-grey--text text--darken-3 mb-1" v-if="cuenta.TipoPrecio != null">Tipo de Precio</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold primary--text">{{cuenta.TipoPrecio | precioVenta}}</v-list-item-title>
                  </v-col>
                  <v-col cols="6" md="3" lg="3">
                    <v-list-item-subtitle class="caption blue-grey--text text--darken-3 mb-1">Recibido</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold primary--text">{{cuenta.fecha_recibido | smartDate }}</v-list-item-title>
                  </v-col>
                  <v-col cols="6" md="3" lg="3">
                    <v-list-item-subtitle class="caption blue-grey--text text--darken-3 mb-1">RIF</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold primary--text">{{cuenta.rif }}</v-list-item-title>
                  </v-col>
                </v-row>
                <v-row no-gutters>                  
                  <v-col cols="6" md="3" lg="3">
                    <v-list-item-subtitle class="caption blue-grey--text text--darken-3 mb-1">Documento</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold primary--text" v-text="cuenta.tipo_doc"></v-list-item-title>
                  </v-col>
                  <v-col cols="6" md="3" lg="3">
                    <v-list-item-subtitle class="caption blue-grey--text text--darken-3 mb-1">Nro. Documento</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold primary--text">{{cuenta.nro_doc}}</v-list-item-title>
                  </v-col>
                  <v-col cols="6" md="3" lg="3">
                    <v-list-item-subtitle class="caption blue-grey--text text--darken-3 mb-1">Días Vencida</v-list-item-subtitle>
                    <v-list-item-title class="text-h5 font-weight-bold primary--text text-wrap">
                      <v-chip
                        small
                        color="red-alert"
                        label
                        class="font-weight-bold"
                      >
                        {{cuenta.dias_vencida}} días
                      </v-chip>
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="6" md="3" lg="3">
                    <v-list-item-subtitle class="caption blue-grey--text text--darken-3 mb-1">Días de Crédito</v-list-item-subtitle>
                    <v-list-item-title class="text-h5 font-weight-bold primary--text text-wrap">
                      <v-chip
                        small
                        color="warning-alert"
                        label
                        class="font-weight-bold"
                      >
                        {{cuenta.dias_creditos}} días
                      </v-chip>
                    </v-list-item-title>
                  </v-col>
                </v-row>                
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="px-0 mx-0 d-flex align-center">
          <v-list style="width: 100%" :class="{'mt-3': $vuetify.breakpoint.smOnly }">
            <v-list-item class=" mt-2 px-0 grey lighten-4 rounded-lg ">
              <v-list-item-content class="px-4">           
                <v-row no-gutters>
                  <v-col cols="12" class="text-right">
                    <span class="caption blue-grey--text text--darken-3">Saldo Neto</span>
                    <v-list-item-title class="text-h4 font-weight-bold primary--text">
                      <span class="mx-1" v-text="currency"></span>
                      {{cuenta.monto_net | FormatCurrency}}
                    </v-list-item-title>
                  </v-col>
                </v-row>  
              </v-list-item-content>
             </v-list-item>
            <v-list-item class=" mt-2 px-0 grey lighten-4 rounded-lg ">
              <v-list-item-content class="px-4">           
                <v-row no-gutters>
                  <v-col cols="12" class="text-right">
                    <span class="caption blue-grey--text text--darken-3">Saldo</span>
                    <v-list-item-title class="text-h4 font-weight-bold red--text">
                      <span class="mx-1" v-text="currency"></span>
                      {{cuenta.saldo | FormatCurrency}} 
                    </v-list-item-title>
                  </v-col>
                </v-row>        
              </v-list-item-content>
             </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
export default {
  name: 'ItemCxc',
  props:{
    cuenta:{
      type: Object,
      default: () => ({})
    },
    type: String,
    currency: String
  },
  filters: {
    precioVenta(val){
      if(val != null){
        let tipo = val.toString().split('');
        return 'Nro. '+tipo[1];
      }

      return val;
    }
  }
}
</script>
<style lang="sass">
.cxc-content .v-expansion-panel-content__wrap
  padding: 5px 24px !important

.red-alert
  background-color: rgba(244,67,54,0.12) !important
  border-color: rgba(244,67,54,0.12) important !important
  color: #F44336 !important

.warning-alert
  background-color: rgba(244,67,54,0.12) !important
  border-color: rgba(244,67,54,0.12) important !important
  color: #F44336 !important

</style>